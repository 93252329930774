<template>

	<div class="reference-page-container">
		<a-tabs v-model:activeKey="activeKey" @change="onTabChanged">
			<a-tab-pane key="1" tab="注册统计">
				<div class="table-tool-bar">
					<div class="table-toolbar-btn" @click="refreshPage('reference')">
						<img src="../images/refresh.svg" alt="refresh" class="table-toolbar-img">
						<div class="table-toolbar-btn-text">
							刷新
						</div>
					</div>
				</div>
				<div class="overview-container">
					<div class="overview-grid-cell">
						<div class="overview-cell-title">今日分享数</div>
						<div class="overview-content">{{ this.todayCount }}</div>
					</div>
					<div class="overview-grid-cell">
						<div class="overview-cell-title">
							昨日分享数
						</div>
						<div class="overview-content">
							{{ this.yesterdayCount }}
						</div>

					</div>
					<div class="overview-grid-cell">
						<div class="overview-cell-title">
							总分享数
						</div>
						<div class="overview-content">
							{{ this.totalCount }}
						</div>

					</div>
				</div>
				<a-pagination v-model:current="this.pageCount" v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onPageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>
				<div class="reference-table-container">
					<div class="reference-table-row">
						<div class="reference-table-cell">邮箱</div>
						<div class="reference-table-cell">注册时间</div>
					</div>
					<template v-for="item in this.referenceRecordList" :key="item._id">

						<div class="reference-table-row">
							<div class="reference-table-cell">{{ item.referToEmail }}</div>
							<div class="reference-table-cell">{{ item.createTimeString }}</div>
						</div>
					</template>
				</div>
			</a-tab-pane>
		</a-tabs>

	</div>
</template>

<script>

import {postRequestAsync, showLoading, hideLoading} from "@/utils/methods";

export default {
	name: "MainPage",
	components: {},
	data() {
		return {
			activeKey: "1",
			pageCount: 1,
			totalCount: 0,
			yesterdayCount: 0,
			todayCount: 0,
			pageSize: 10,
			referenceRecordList: []
		}
	},
	async mounted() {
		showLoading()
		try {
			await this.refreshPage()
		} finally {
			hideLoading()
		}

	},
	methods: {
		onTabChanged(){

		},
		async refreshPage(){
			const referInfoRes = await postRequestAsync('dis/getReferenceInfo', {page: 1})

			if (referInfoRes?.code === 200 && referInfoRes?.msg) {
				this.totalCount = referInfoRes?.msg?.totalCount
				this.todayCount = referInfoRes?.msg?.todayCount
				this.yesterdayCount = referInfoRes?.msg?.yesterdayCount
			}
			await this.getReferenceList()
		},
		async onPageChanged(data) {
			this.pageCount = data
			await this.getReferenceList()
		},
		async getReferenceList () {
			showLoading()
			try{
				const response = await postRequestAsync('dis/getReference', {page: this.pageCount})

				if (response?.code === 200 && response?.msg) {
					this.referenceRecordList = response?.msg?.list
				}
			} finally {
				hideLoading()
			}

		}
	}
}
</script>

<style scoped>

.reference-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
	padding: 0.4em;
	box-sizing: border-box;
}

.manual-page-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0 3em;
}

.manual-page-panel-content-container {

}

.manual-collapse-header {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 900px) {
	.manual-page-container {
		padding: 0 0.8em !important;
	}
}

.reference-table-container {
	width: 100%;
	display: table;
	color: #E6D5B8;
}

.reference-table-row {
	display: table-row;
}

.reference-table-cell {
	display: table-cell;
	padding: 0.5em 1em;
	border-bottom: 1px solid #E6D5B8;
	text-align: left;
}

.reference-total-line {
	width: 100%;

	color: #E6D5B8;
	text-align: left;
	padding-bottom: 0.4em;
}

.dividend-record-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	color: #E6D5B8;
	align-items: start;
	border-bottom: 1px solid #E6D5B8;
	padding: 0.4em;
}

.dividend-record-title {
	font-size: 1.2em;
	padding-bottom: 0.4em;
	color: #FF8900;
}

.is-not-received {
	color: #FF8900 !important;
	width: 100%;
	text-align: end;
	font-size: 1.2em;
}

.is-received {
	width: 100%;
	text-align: end;
	font-size: 1.2em;
}

.dividend-tab-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.dividend-btn-container {
	width: 100%;
	margin-top: 1em;
}

.withdraw-dividend-btn {

}

.dividend-wallet-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: start;
	border: 1px solid #FF8900;
	border-radius: 0.4em;
	padding: 0.6em;
}

.account-info-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: start;
	color: #FF8900;
}

.account-info-line {
	margin-bottom: 0.4em;
	font-size: 1.4em;
}

.dividend-wallet-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	width: 100%;
}

.dividend-wallet-content-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	width: 100%;
}

.overview-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}


.overview-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.overview-content {
	font-size: 1.4em;
}

.overview-cell-title {
	padding-top: 0.4em;
}

.table-tool-bar {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	width: 100%;
	margin-bottom: 0.4em;
}

.table-toolbar-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
}

.table-toolbar-img {
	width: 1.2em;
	margin-right: 0.2em;
}

.table-toolbar-btn-text {
	font-size: 1.2em;
	color: #E6D5B8;
	white-space: nowrap;
}

.overview-tab-content-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

@media only screen and (max-width: 900px) {
	.overview-container {
		grid-template-columns: 1fr 1fr; /* 列宽度设置为相等的三列 */
		gap: 0.4em; /* 网格间距 */
	}

	.overview-tab-content-container {

		grid-template-columns: 1fr 1fr; /* 列宽度设置为相等的三列 */
		gap: 0.4em; /* 网格间距 */
	}
}

.withdraw-dividend-btn {
	background-color: #FF8900;
	color: #191123;
	margin-bottom: 0.4em;
	font-size: 1.4em;
	padding: 0.1em 0.4em;
	cursor: pointer;
	border-radius: 0.2em;
}

.withdraw-dividend-btn:active {
	transform: scale(0.95);
}

</style>