import {createApp} from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from './router';
import store from '@/utils/store';
import {getServerUrl, getRequestAsync} from '@/utils/methods'

const app = createApp(App);

router.beforeEach(async (to, from, next) => {
	await getServerUrl()
	if (to.name === 'main') {
		const response = await getRequestAsync('dis/verifyFp')
		if (response?.code !== 200) {
			next({name: 'login'})
			return
		}
	}
	if (to.name === 'login') {
		const response = await getRequestAsync('dis/verifyFp')
		if (response?.code === 200) {
			next({name: 'main'})
			return
		}
	}
	next()
});


app.use(router)
app.use(Antd)
app.use(store)
app.mount('#app')
