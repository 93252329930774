<template>
	<div class='sl-login-modal-bg'>
		<div class='sl-login-modal'>
			<div class='sl-login-modal-container'>
				<div class='sl-login-title-container' v-if="showLogo === true">
					<img src="../images/logo.svg" alt="alpha" class='sl-login-title-icon'>
				</div>

<!--				<div class='sl-login-title-container' v-if="showLogo === true">-->
<!--					欢迎使用星云仪表盘-->
<!--				</div>-->
				<div class='sl-login-modal-title'>用户登录</div>
				<div class='sl-login-modal-field-label'>邮箱</div>
				<div class='sl-login-email-field-container'>
					<input class='sl-login-input-email' type="email" placeholder='请在此输入您的邮箱' v-model='this.email'/>
				</div>
				<div class='sl-login-modal-field-label'>密码</div>
				<input class='sl-login-input' type="password" placeholder='请在此输入密码' v-model='this.password'/>
<!--				<div class='sl-login-modal-new-user' @click="this.registerNewUser" v-if="showLogo === true">新用户注册</div>-->
<!--				<div class='sl-login-modal-new-user' @click="this.forgotPassword" v-if="showLogo === true">忘记密码</div>-->
				<div class='sl-login-btn-container-login-modal'>
					<a-button type="primary" size="large" @click="onLoginConfirm">
						登录
					</a-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {postRequest, verifyEmail, getFingerprint, postRequestAsync} from '@/utils/methods'

export default {
	name: 'LoginModal',
	data() {
		return {
			email: '',
			password: '',
			showLogo: true,
			loginUrl: 'dis/login'
		}
	},
	props: ['canShowLogo'],
	mounted() {
		if (this.canShowLogo === false) {
			this.showLogo = false
		}
	},
	methods: {
		async onLoginConfirm() {

			if (!this.email) {
				// showToast('请输入邮箱')
				this.$message.error('请输入邮箱');
				return;
			}
			if (!verifyEmail(this.email)) {
				this.$message.error('邮箱格式不正确');

				return;
			}
			if (!this.password) {

				this.$message.error('请输入密码');
				return;
			}
			const fp = await getFingerprint()
			const response = await postRequestAsync(this.loginUrl, {email: this.email, password: this.password, fp: fp})

			if (response?.code === 200) {
				this.$message.success('登录成功，欢迎回来!')
				this.$emit('onLoginSuccess')
				return
			}

			if (response?.code === 201) {

				this.$message.error('用户不存在')
			} else if (response?.code === 204) {
				this.$message.error('用户名密码不匹配')
			} else {
				this.$message.error('登录失败，请重试')
			}

		},
		registerNewUser() {
			this.$emit('onNewUser');
		},
		forgotPassword() {
			this.$emit('onForgotPassword');
		}
	}
}
</script>

<style scoped>

.sl-login-btn-container-login-modal {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	align-items: center;
	justify-content: end;
}

.sl-login-btn-confirm-login-modal {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	cursor: pointer;
	user-select: none;
}

.sl-login-btn-confirm-login-modal:active {
	transform: scale(0.9);
}

.sl-login-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*backdrop-filter: blur(8px) opacity(100%) brightness(90%);*/
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
}

.sl-login-modal {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	width: 48em;
	top: 5em;
	border-radius: 1em;
	/*border: 1px solid rgba(255, 137, 0, 0.44);*/
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
}

.sl-login-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em 3em;
}

.sl-login-modal-title {
	font-size: 1.6em;
	width: 100%;
	text-align: center;
	color: #FF8900;
}

.sl-login-modal-field-label {
	font-size: 1.4em;
	margin-top: 0.4em;
	color: #FF8900;
}

.sl-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-login-input-email, .sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	color: #FF8900;
	font-size: 1.4em;
	background: transparent !important;
	margin-top: 0.4em;
	flex: 1 1;
	padding: 0 !important;
}

input.sl-login-input:focus, .sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-login-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	width: 100%;
}

.sl-login-btn-confirm-login-modal {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	cursor: pointer;
}

.sl-login-btn-cancel-login-modal {
	background-color: #3D3020;
	font-size: 1.4em;
	color: #FF8900;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	border: 1px solid #FF8900;
	margin-right: 3em;
	cursor: pointer;
}

input.sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}
</style>