import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import MainPage from './pages/MainPage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: LoginPage, name: 'login'},
		{path: '/login', component: LoginPage, name: 'login'},
		{path: '/main', component: MainPage, name: 'main'},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login' // 重定向到默认路径
		}
	]
});

export default router;