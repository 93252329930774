<template>

	<div class="main-container">

		<LoginModal @onLoginSuccess="onLoginSuccess"></LoginModal>
	</div>
</template>
<script>

import LoginModal from "../components/LoginModal.vue";
export default {
	name: "LoginPage",
	components: {
		LoginModal
	},
	data() {
		return {
			showNotificationModal: false,
			notificationMessage: '亲爱的用户您好，\n我们的服务器近期遭到了网络攻击，造成了数据丢失。如果您的会员信息丢失，请重新注册后将最后一次购买的截图发送至：george.gu@nebulabinary.com这个邮箱，客服这边会帮您处理。\n感谢您的理解和支持。\n\n星云团队'
		}
	},
	async mounted() {
	},
	methods: {
		onLoginSuccess() {
			this.$router.push({
				name: 'main'
			})
		}
	}
}
</script>
<style scoped>

.main-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-image: url('../images/matrix_bg.jpg');
	top: 0;
	left: 0;
}
</style>